/* Screen sizes */
$irt-xs: 0px;
$irt-sm: 576px;
$irt-md: 768px;
$irt-lg: 992px;
$irt-xl: 1200px;
$irt-xxl: 1640px;

/* Margins */
$irt-half-margin: 12px;
$irt-margin: 24px;
$irt-double-margin: 48px;

/* Toolbar */
$irt-toolbar-height: 64px;
$irt-toolbar-font-size: 14px;

/* Images */
$irt-logo-width: 180px;
$irt-logo-big-width: 250px;

/* Borders */
$irt-border-width: 2px;
$irt-border-radius: 10px;
$irt-button-border-radius: 5px;
$irt-border-elevation: 16;

/* Fonts */
$irt-title-font-size: 46px;
$irt-title-medium-font-size: 38px;
$irt-title-small-font-size: 32px;

/* Buttons */
$irt-icon-button-size: 40px;
