@import '~@angular/material/theming';
@import '~src/values/colors';
@import '~src/values/dimens';

/* Customize Angular Material typography */
@font-face {
  font-family: Oswald;
  src: url(assets/fonts/Oswald.ttf) format('opentype');
}

@font-face {
  font-family: MsMadi;
  src: url(assets/fonts/MsMadi-Regular.ttf) format('opentype');
}

@font-face {
  font-family: Rubik;
  src: url(assets/fonts/Rubik-VariableFont_wght.ttf) format('opentype');
}
$sp-custom-typography: mat-typography-config(
  $font-family: Rubik,
);
@include mat-core($sp-custom-typography);

/* Customize Angular Material theme */
$irent-primary: mat-palette($irt-primary-palette);
$irent-accent: mat-palette($irt-accent-palette);
$irent-warn: mat-palette($mat-red);
$irent-theme: mat-light-theme($irent-primary, $irent-accent, $irent-warn);
@include angular-material-theme($irent-theme);

/* Customize project global styles */
.mat-dialog-container {
  padding: 0 !important;
  border-radius: $irt-border-radius !important;
}

.mat-icon-button {
  border-radius: calc(#{$irt-border-radius} / 2) !important;
}

.mat-expansion-panel-header {
  height: fit-content !important;
  min-height: $irt-double-margin;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Rubik, Oswald, Roboto, 'Helvetica Neue', sans-serif;
}

.irt-primary-color {
  color: mat-contrast($irt-primary-palette, 500);
}

.irt-primary-medium-color {
  color: mat-contrast($irt-primary-palette, 900);
}

.irt-accent-color {
  color: mat-color($irt-accent-palette, 500);
}

.irt-accent-background {
  background-color: mat-color($irt-accent-palette, 500);
}

.irt-primary-medium-background {
  background-color: mat-contrast($irt-primary-palette, 600);
}

.irt-half-padding {
  padding: $irt-half-margin;
  box-sizing: border-box;
}

.irt-padding {
  padding: $irt-margin;
  box-sizing: border-box;
}

.irt-double-padding-md {
  padding: $irt-double-margin;
  box-sizing: border-box;

  @media (max-width: ($irt-md - 1)) {
    padding: $irt-margin;
  }
}

.irt-no-margin {
  margin: 0 !important;
}

.irt-margin-left {
  margin-left: $irt-margin !important;
}

.irt-margin-right {
  margin-right: $irt-margin !important;
}

.irt-margin-top {
  margin-top: $irt-margin !important;
}

.irt-margin-bottom {
  margin-bottom: $irt-margin !important;
}

.irt-double-margin-top {
  margin-top: $irt-double-margin !important;
}

.irt-double-margin-left {
  margin-left: $irt-double-margin !important;
}

.irt-half-margin-bottom {
  margin-bottom: $irt-half-margin !important;
}

.irt-half-margin-top {
  margin-top: $irt-half-margin !important;
}

.irt-half-margin-left {
  margin-left: $irt-half-margin !important;
}

.irt-half-margin-right {
  margin-right: $irt-half-margin !important;
}

.irt-half-margin {
  margin: $irt-half-margin !important;
}

.irt-third-width {
  width: 31.5%;
}

.irt-two-third-width {
  width: 65.5%;
}

.irt-half-width {
  width: 48.5%;
}

.irt-full-width {
  width: 100%;
}

.irt-full-height {
  height: 100%;
}

.irt-row {
  display: flex;
}

.irt-centered-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.irt-spaced-row {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.irt-centered-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.irt-centered-text {
  text-align: center;
}

.irt-right-text {
  text-align: right;
}

.irt-form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.irt-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.irt-main-button {
  border-radius: $irt-button-border-radius !important;
  border: $irt-border-width solid mat-color($irt-primary-palette, 500) !important;
  background-color: mat-color($irt-primary-palette, 500);
  color: mat-contrast($irt-primary-palette, 500);
}

.irt-main-disabled-button {
  border-radius: $irt-button-border-radius !important;
  border: $irt-border-width solid mat-color($irt-primary-palette, 500) !important;
  background-color: mat-color($irt-primary-palette, 500);
  color: mat-contrast($irt-primary-palette, 500) !important;
  opacity: 0.5;
  box-sizing: border-box;
}

.irt-secondary-button {
  border-radius: $irt-button-border-radius !important;
  border: $irt-border-width solid mat-color($irt-primary-palette, 500) !important;
  color: mat-color($irt-primary-palette, 500);
}

.irt-title {
  font-size: $irt-title-font-size;

  @media (max-width: ($irt-md - 1)) {
    font-size: $irt-title-small-font-size;
  }

  @media (min-width: $irt-md) and (max-width: $irt-lg) {
    font-size: $irt-title-medium-font-size;
  }
}

.irt-ellipsized-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.irt-cursor-pointer {
  cursor: pointer;
}

.irt-hero-fab-button {
  position: absolute !important;
  bottom: $irt-margin;
  right: $irt-margin;

  @media (max-width: ($irt-lg - 1)) {
    display: none !important;
  }
}

.irt-flex-wrap {
  flex-wrap: wrap;
}

.irt-spinner-layout {
  padding: $irt-half-margin 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.irt-border-radius {
  border-radius: $irt-border-radius;
}

.irt-calendar-start-date {
  background-color: mat-color($irt-accent-palette, 500);
  border-radius: 50%;
}

.irt-calendar-interval-date {
  background-color: mat-color($irt-accent-palette, 500);
  border-radius: 50%;
  opacity: 0.5;
}

.irt-calendar-end-date {
  background-color: black;
  border-radius: 50%;
}

.irt-calendar-not-selected-date {
  background-color: white;
  border-radius: 0;
}

.irt-calendar-start-date .mat-calendar-body-cell-content {
  color: white !important;
}

.irt-calendar-interval-date .mat-calendar-body-cell-content {
  color: white !important;
}

.irt-calendar-end-date .mat-calendar-body-cell-content {
  color: white !important;
}

.irt-sign-text {
  font-family: MsMadi, Rubik, Oswald, Roboto, 'Helvetica Neue', sans-serif;
}
